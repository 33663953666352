
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { message, Empty } from 'ant-design-vue'
import { schoolPower, schoolMangers, overviewList, putSchoolMangers, overviewCourseList, authCourse, batchAuth, getSceneList, getSchoolScene, editSceneAuth, getParkList } from '@/api/index'
import { filterKeys } from '@/hooks/common-hooks'
import { TYPE } from '@/hooks/config'
export interface FromState {
  school_id: number;
  overview_id: string | undefined;
  park_id: string | undefined;
  is_overview: boolean;
  is_ar: boolean;
  is_mr: boolean;
  is_park: boolean;
  is_resource: boolean;
  is_resource_teach: boolean;
  is_resource_simulate: boolean;
  is_resource_exercise: boolean;
  is_training: boolean;
  is_product: boolean;
  is_queue: boolean;
  is_product_order: boolean;
  is_product_sop: boolean;
  is_live: boolean;
  userManagers: number[];
}
export default defineComponent({
  name: 'Manger',
  props: ['id'],
  components: {
    // SwapOutlined,
    // uploadAb,
    // DeleteOutlined,
    // PlusCircleOutlined
  },
  setup (props) {
    const formState: FromState = reactive({
      school_id: parseInt(props.id),
      overview_id: ref<string | undefined>(undefined),
      park_id: ref<string | undefined>(undefined),
      is_overview: false,
      is_ar: false,
      is_mr: false,
      is_park: false,
      is_resource: false,
      is_resource_teach: false,
      is_resource_simulate: false,
      is_resource_exercise: false,
      is_training: false,
      is_product: false,
      is_queue: false,
      is_product_order: false,
      is_product_sop: false,
      is_live: false,
      userManagers: []
    })
    console.log(formState)
    const schoolMangersList = ref([])
    const overviewLists = ref([])
    const parkLists = ref([])
    const courseList = ref([])
    const course_ids = ref([])
    const sin_course_ids = ref([])
    const sceneList = ref([])
    const scene_ids = ref([])
    const fileterCourse = (list: any, type_id: any) => {
      return list.filter((item: any) => {
        return item.type_id === type_id
      })
    }
    // 获取全部场景
    const fetchGetSceneList = () => {
      getSceneList({}).then(res => {
        sceneList.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: `${item.id}-${item.scene}`,
            label: `${item.id}-${item.scene}`
          }
        })
      })
    }
    const getSceneIds = () => {
      getSchoolScene({ school_id: parseInt(props.id) }).then(res => {
        scene_ids.value = res.data.map((item: any) => item.id)
      })
    }
    // 场景授权
    const sceneAuth = () => {
      // if (scene_ids.value.length === 0) return
      editSceneAuth({
        school_id: parseInt(props.id),
        scene_ids: scene_ids.value
      }).then(res => {
        getSceneIds()
      })
    }
    // 授权绑定
    const getSchoolPower = () => {
      schoolPower({ id: props.id }).then(res => {
        if (res.data) {
          filterKeys(formState, res.data)
          formState.overview_id = formState.overview_id ? formState.overview_id : undefined
          formState.park_id = formState.park_id ? formState.park_id : undefined
          formState.userManagers = formState.userManagers.map((item: any) => { return item.id })
        }
      })
    }
    // 学校下的管理人下拉
    const getSchoolMangers = () => {
      schoolMangers({ school_id: props.id }).then(res => {
        schoolMangersList.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: item.nickname,
            label: item.nickname
          }
        })
      })
    }
    const getOverviewList = () => {
      overviewList({}).then(res => {
        overviewLists.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: `${item.id}-${item.name}`,
            label: `${item.id}-${item.name}`
          }
        })
      })
    }
    const getParkLists = () => {
      getParkList({}).then(res => {
        parkLists.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: `${item.id}-${item.name}`,
            label: `${item.id}-${item.name}`
          }
        })
      })
    }

    const getCourseList = () => {
      overviewCourseList({}).then(res => {
        courseList.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: `${item.id}-${item.name}`,
            label: `${item.id}-${item.name}`,
            type_id: item.type_id
          }
        })
      })
    }
    const getCouseIDs = () => {
      authCourse({ school_id: parseInt(props.id) }).then(res => {
        course_ids.value = res.data.filter((item: any) => {
          return item.type_id === 1
        }).map((item: any) => item.id)
        sin_course_ids.value = res.data.filter((item: any) => {
          return item.type_id === 2
        }).map((item: any) => item.id)
      })
    }
    const filterOverview = (input: string, option: any) => {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    const filterCourse = (input: string, option: any) => {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    const filterUser = (input: string, option: any) => {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 添加课程
    const batchAuthHttp = () => {
      if (course_ids.value.length > 0) {
        batchAuth({
          school_id: parseInt(props.id),
          course_ids: course_ids.value.concat(sin_course_ids.value)
        }).then(res => {
          getCouseIDs()
        })
      } else {
        message.error('请先选择课程')
      }
    }
    // 添加 修改
    const saveSubmit = () => {
      putSchoolMangers({ ...formState }).then(res => {
        message.success('保存成功')
        if (formState.is_resource) {
          batchAuthHttp()
        }
        sceneAuth()
        getSchoolMangers()
      })
    }
    onMounted(() => {
      getOverviewList()
      getParkLists()
      getCourseList()
      getCouseIDs()
      getSchoolMangers()
      getSchoolPower()
      fetchGetSceneList()
      getSceneIds()
    })
    return {
      value: 1,
      ...toRefs(formState),
      schoolMangersList,
      overviewLists,
      parkLists,
      getParkLists,
      saveSubmit,
      courseList,
      filterCourse,
      filterOverview,
      filterUser,
      course_ids,
      sceneList,
      scene_ids,
      fileterCourse,
      sin_course_ids
      // simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      // activeKey: ref('1')
    }
  }
})
